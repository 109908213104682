export {
    mediaQuery,
    istDesktop,
    istTablet,
    istTabletDown,
    istMobil,
    istTouchEndgeraet,
};

function mediaQuery(property, value) {
    return window.matchMedia(`screen and (${property}:${value}px)`).matches;
}

function istDesktop() {
    return mediaQuery("min-width", 801);
}

function istTablet() {
    return mediaQuery("min-width", 481);
}

function istTabletDown() {
    return mediaQuery("max-width", 800);
}

function istMobil() {
    return mediaQuery("max-width", 480);
}

function istTouchEndgeraet() {
    return window.matchMedia("(hover: none) and (pointer: coarse)").matches;
}
